<!--Progressbar y estilo de: http://hilongjw.github.io/vue-progressbar/index.html-->
<template>
  <v-app
    :style="{
      'background-image': 'linear-gradient(120deg, #155799, #159957)',
      color: '#fff'
    }"
  >
    <vue-progress-bar></vue-progress-bar>
    <v-container fill-height>
      <v-layout align-center>
        <v-flex>
          <div v-if="paso == 0">
            <center>
              <!--
              <h1>🎁¿Buscas el regalo ideal?🎁</h1>
              <br>
              <h1>🎁</h1>
              <br>
              -->
              <v-layout row wrap>
                <v-flex xs0.5></v-flex>
                <v-flex xs1><h1>&nbsp;📱</h1></v-flex>
                <v-flex xs9>
                  <h1>Encuentra el movil ideal {{ id }}</h1>
                </v-flex>
                <v-flex xs1>
                  <h1>📱&nbsp;</h1>
                </v-flex>
                <v-flex xs0.5></v-flex>
              </v-layout>
              <br />
              <br />
              <!--
                <h2>Responde el test y encuentra el perfecto para cada persona</h2>
              -->
              <h2>Responde el test y encuentra el perfecto</h2>
              <br />
              <v-btn color="success" @click="siguiente()">
                Comenzar ➡
              </v-btn>
            </center>
          </div>
          <div v-else-if="paso == 1">
            <center>
              <h1>¿Cuanto quieres gastar?</h1>
              <br />
              <v-btn @click="(dinero = 'poco'), siguiente(5, 'poco')">
                max 100€ 💷
              </v-btn>
              <br />
              <br />
              <v-btn @click="(dinero = 'bajo'), siguiente(5, 'bajo')">
                max 200€ 💰
              </v-btn>
              <br />
              <br />
              <v-btn @click="(dinero = 'medio'), siguiente(5, 'medio')">
                max 300€ 💰
              </v-btn>
              <br />
              <br />
              <v-btn @click="(dinero = 'mucho'), siguiente(5, 'mucho')">
                max 500€ 💳
              </v-btn>
              <br />
              <br />
              <v-btn @click="(dinero = 'atope'), siguiente(5, 'atope')">
                +500€ 💳💳
              </v-btn>
            </center>
          </div>
          <div v-else-if="paso == 2">
            <center>
              <!--<h1>¿Que es para ti?</h1>-->
              <h1>¿Que tamaño tiene que tener?</h1>
              <br />
              <v-btn @click="(tamano = 'pequeno'), siguiente()">
                Pequeño (max 5 pulgadas) 🐭
              </v-btn>
              <br />
              <br />
              <v-btn @click="(tamano = 'normal'), siguiente()">
                Normal (5-6.5 pulgadas) 🐑
              </v-btn>
              <br />
              <br />
              <v-btn @click="(tamano = 'grande'), siguiente()">
                Grande (+6.5 pulgadas) 🐘
              </v-btn>
            </center>
          </div>
          <div v-else-if="paso == 3">
            <center>
              <!--<h1>¿Que es para ti?</h1>-->
              <h1>¿Cómo tiene que ser la camara?</h1>
              <br />
              <v-btn @click="(camara = 'mala'), siguiente()">
                Me da igual 📷
              </v-btn>
              <br />
              <br />
              <v-btn @click="(camara = 'mediana'), siguiente()">
                Buena 📸
              </v-btn>
              <br />
              <br />
              <v-btn @click="(camara = 'buena'), siguiente()">
                Perfecta 🤳
              </v-btn>
            </center>
          </div>
          <div v-else-if="paso == 4">
            <center>
              <h1>¡ Buscando tu movil perfecto !</h1>
              <br />
              <br />
              <v-progress-circular
                :rotate="-90"
                :size="200"
                :width="20"
                :value="progreso_circular"
                color="white"
              >
                <h2>{{ progreso_circular }}</h2>
              </v-progress-circular>
              <br />
              <br />
              <br />
              <v-layout row wrap>
                <v-flex xs0.5></v-flex>
                <v-flex xs1><h1 v-if="visibilidad_final">&nbsp;📱</h1></v-flex>
                <v-flex xs9>
                  <v-btn
                    v-if="visibilidad_final"
                    color="success"
                    @click="final()"
                  >
                    <h2>
                      ¡Date un capricho!
                    </h2>
                  </v-btn>
                </v-flex>
                <v-flex xs1>
                  <h1 v-if="visibilidad_final">📱&nbsp;</h1>
                </v-flex>
                <v-flex xs0.5></v-flex>
              </v-layout>
            </center>
          </div>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>
<script>
export default {
  props: ["id"],
  data() {
    return {
      dinero: "medio",
      tamano: "normal",
      paso: 0,
      numero_total_de_pasos: 4,
      interval: {},
      progreso_circular: 0,
      visibilidad_final: false,
      actual: ""
    };
  },
  methods: {
    siguiente() {
      this.paso = this.paso + 1;

      var incremento = Math.ceil(100 / this.numero_total_de_pasos);
      if (this.paso == 1) {
        this.$Progress.set(incremento);
      } else if (this.paso == this.numero_total_de_pasos) {
        //Barra de progreso al maximo
        this.$Progress.set(100);

        //Progreso circular
        this.interval = setInterval(() => {
          if (this.progreso_circular >= 100) {
            this.visibilidad_final = true;
          } else {
            //this.progreso_circular += 10
            var a_sumar = Math.floor(Math.random() * 3 + 1) + 12;
            if (a_sumar + this.progreso_circular > 100) {
              this.progreso_circular = 100;
            } else {
              this.progreso_circular += a_sumar;
            }
          }
        }, 300);
      } else {
        this.$Progress.increase(incremento);
      }
    },
    final() {
      var salidaGtag = this.dinero.toString() + this.tamano.toString();
      this.$gtag.event("moviles", { event_category: salidaGtag });

      var link = "";

      if (this.dinero == "poco") {
        //Menos de 100
        if (this.tamano == "pequeno") {
          //5 pulgadas --> ZTE Blade L8 Black 71,5€
          link = "https://amzn.to/3aeL4aL";
        } else if (this.tamano == "normal") {
          //5 a  6.5 pulgadas --> REDMI 8A 97,49€
          link = "https://amzn.to/3aeL4aL";
        } else {
          // Mas de 6.5 pulgadas --> DOOGEE N20
          link = "https://amzn.to/2TsjOzi";
        }
      } else if (this.dinero == "bajo") {
        //Menos de 200
        if (this.tamano == "pequeno") {
          //5 pulgadas --> ZTE Blade L8 Black 71,5€
          link = "https://amzn.to/3aeL4aL";
        } else if (this.tamano == "normal") {
          //5 a  6.5 pulgadas --> Xiaomi Redmi Note 7 165€
          link = "https://amzn.to/2uTc3ID";
        } else {
          // Mas de 6.5 pulgadas --> Elephone PX 170€
          link = "https://amzn.to/2tlngRQ";
        }
      } else if (this.dinero == "medio") {
        //Menos de 300
        if (this.tamano == "pequeno") {
          //5 pulgadas --> SAMSUNG Galaxy XCOVER 4S
          link = "https://amzn.to/2tclRx3";
        } else if (this.tamano == "normal") {
          //5 a  6.5 pulgadas --> Xiaomi Mi 9T 276€
          link = "https://amzn.to/2tnT9Jn";
        } else {
          // Mas de 6.5 pulgadas --> Xiaomi Redmi Note 8 Pro
          link = "https://amzn.to/3abopMk";
        }
      } else if (this.dinero == "mucho") {
        //Menos de 500
        if (this.tamano == "pequeno") {
          //5 pulgadas --> SAMSUNG Galaxy XCOVER 4S
          link = "https://amzn.to/2tclRx3";
        } else if (this.tamano == "normal") {
          //5 a  6.5 pulgadas --> Samsung Galaxy S10e
          link = "https://amzn.to/2TqRCNj";
        } else {
          // Mas de 6.5 pulgadas --> Samsung Galaxy A51 369€
          link = "https://amzn.to/35ZjOJM";
        }
      } else {
        //Mas de 500
        if (this.tamano == "pequeno") {
          //5 pulgadas --> Samsung Galaxy Fold 2.779€
          link = "https://amzn.to/2uKPMMW";
        } else if (this.tamano == "normal") {
          //5 a  6.5 pulgadas --> Samsung Galaxy S10+ 718€
          link = "https://amzn.to/2TqS0LL";
        } else {
          // Mas de 6.5 pulgadas --> Samsung Galaxy Note10 826€
          link = "https://amzn.to/2FPe7DE";
        }
      }
      location.href = link;
    }
  },
  name: "App"
};
</script>

<style scoped>
h1 {
  font-size: 32px;
}
h2 {
  font-size: 24px;
}
button {
  font-size: 18px;
}
</style>
