<!--Progressbar y estilo de: http://hilongjw.github.io/vue-progressbar/index.html-->
<template>
  <v-app
    :style="{
      'background-image': 'linear-gradient(120deg, #155799, #159957)',
      color: '#fff'
    }"
  >
    <vue-progress-bar></vue-progress-bar>
    <v-container fill-height>
      <v-layout align-center>
        <v-flex>
          <div v-if="paso == 0">
            <center>
              <!--
              <h1>🎁¿Buscas el regalo ideal?🎁</h1>
              <br>
              <h1>🎁</h1>
              <br>
              -->
              <v-layout row wrap>
                <v-flex xs0.5></v-flex>
                <v-flex xs1><h1>&nbsp;🎁</h1></v-flex>
                <v-flex xs9>
                  <h1>¿Buscas el patinete ideal? {{ id }}</h1>
                </v-flex>
                <v-flex xs1>
                  <h1>🎁&nbsp;</h1>
                </v-flex>
                <v-flex xs0.5></v-flex>
              </v-layout>
              <br />
              <br />
              <!--
                <h2>Responde el test y encuentra el perfecto para cada persona</h2>
              -->
              <h2>Responde el test y encuentra el perfecto</h2>
              <br />
              <v-btn color="success" @click="siguiente()">
                Comenzar ➡
              </v-btn>
            </center>
          </div>
          <div v-else-if="paso == 1">
            <center>
              <h1>¿Que uso le vas a dar?</h1>
              <br />
              <v-btn @click="(uso = 'ocio'), siguiente()">
                Ocio 🎳
              </v-btn>
              <br />
              <br />
              <v-btn @click="(uso = 'transporte'), siguiente()">
                Transporte 💻
              </v-btn>
            </center>
            <!--
            <center>
              <h1>¿Para quien es?</h1>
              <br />
              <v-btn @click="(relacion = 'familia'), siguiente()">
                Familia 👨‍👩‍👧‍👧
              </v-btn>
              <br />
              <br />
              <v-btn @click="(relacion = 'amigo'), siguiente()">
                Amigo 🎳
              </v-btn>
              <br />
              <br />
              <v-btn @click="(edad = 'pareja'), siguiente()">
                Pareja 👫
              </v-btn>
              <br />
              <br />
              <v-btn @click="(edad = 'companero'), siguiente()">
                Compañero 💻
              </v-btn>
            </center>
            -->
          </div>
          <div v-else-if="paso == 2">
            <center>
              <h1>¿Cuanto quieres gastar?</h1>
              <br />
              <v-btn @click="(dinero = 'poco'), siguiente()">
                0-200€ 💷
              </v-btn>
              <br />
              <br />
              <v-btn @click="(dinero = 'medio'), siguiente()">
                200-400€ 💰
              </v-btn>
              <br />
              <br />
              <v-btn @click="(dinero = 'mucho'), siguiente()">
                +400€ 💳
              </v-btn>
            </center>
          </div>
          <div v-else-if="paso == 3">
            <center>
              <h1>¡ Buscando el patinete perfecto !</h1>
              <br />
              <br />
              <v-progress-circular
                :rotate="-90"
                :size="200"
                :width="20"
                :value="progreso_circular"
                color="white"
              >
                <h2>{{ progreso_circular }}</h2>
              </v-progress-circular>
              <br />
              <br />
              <br />
              <v-btn v-if="visibilidad_final" color="success" @click="final()">
                <h2>🎁 Abrir 🎁</h2>
              </v-btn>
            </center>
          </div>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>
<script>
import { StoreDB } from "@/services/fireinit.js";

export default {
  props: ["id"],
  data() {
    return {
      paso: 0,
      numero_total_de_pasos: 3,
      edad: "bebe",
      uso: "ocio",
      genero: "hombre",
      relacion: "familia",
      dinero: "poco",
      af_playa: "",
      af_peli: "",
      interval: {},
      progreso_circular: 0,
      visibilidad_final: false,

      areas: {},

      actual: ""
    };
  },
  created() {
    //this.actual = this.$route.params.id;
    //console.log(this.$route);
    //console.log(this.$router)
    StoreDB.collection("areas")
      .get()
      .then(querySnapshot => {
        const documents = querySnapshot.docs.map(doc => doc.data());
        //console.log(documents);
        this.areas = documents;
        // do something with documents
      });
  },

  mounted() {
    //console.log(this.$route.params.id);
    //this.$ga.page('/')
  },
  methods: {
    siguiente() {
      this.paso = this.paso + 1;

      var incremento = Math.ceil(100 / this.numero_total_de_pasos);
      if (this.paso == 1) {
        this.$Progress.set(incremento);
      } else if (this.paso == this.numero_total_de_pasos) {
        //Barra de progreso al maximo
        this.$Progress.set(100);

        //Progreso circular
        this.interval = setInterval(() => {
          if (this.progreso_circular >= 100) {
            this.visibilidad_final = true;
          } else {
            //this.progreso_circular += 10
            var a_sumar = Math.floor(Math.random() * 3 + 1) + 12;
            if (a_sumar + this.progreso_circular > 100) {
              this.progreso_circular = 100;
            } else {
              this.progreso_circular += a_sumar;
            }
          }
        }, 300);
      } else {
        this.$Progress.increase(incremento);
      }
    },
    final() {
      var link = "";
      if (this.dinero == "poco") {
        link = "https://amzn.to/36w6Ep5"; //Patinete 179
      } else if (this.dinero == "medio") {
        link = "https://amzn.to/2YB7cZB"; //Patinete 349 - Xiaomi
      } else {
        link = "https://amzn.to/3d6ekkp"; //Patinete 435
      }
      location.href = link;
    }
  },
  name: "App"
};
</script>

<style scoped>
h1 {
  font-size: 32px;
}
h2 {
  font-size: 24px;
}
button {
  font-size: 18px;
}
</style>
