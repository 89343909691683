<!--Progressbar y estilo de: http://hilongjw.github.io/vue-progressbar/index.html-->
<template>
  <v-app
    :style="{
      'background-image': 'linear-gradient(120deg, #155799, #159957)',
      color: '#fff'
    }"
  >
    <vue-progress-bar></vue-progress-bar>
    <v-container fill-height>
      <v-layout align-center>
        <v-flex>
          <div v-if="paso == 0">
            <center>
              <v-layout row wrap>
                <v-flex xs0.5></v-flex>
                <v-flex xs1><h1>&nbsp;💪</h1></v-flex>
                <v-flex xs9>
                  <h1>¡ Entrena desde casa !</h1>
                </v-flex>
                <v-flex xs1>
                  <h1>💪&nbsp;</h1>
                </v-flex>
                <v-flex xs0.5></v-flex>
              </v-layout>
              <br />
              <br />
              <!--
                <h2>Responde el test y encuentra el perfecto para cada persona</h2>
              -->
              <h2>No hay excusas</h2>
              <br />
              <v-btn color="success" @click="siguiente()">
                Comenzar ➡
              </v-btn>
            </center>
          </div>
          <div v-else-if="paso == 1">
            <center>
              <!--<h1>¿Que es para ti?</h1>-->
              <h1>¿Que buscas?</h1>
              <br />
              <v-btn @click="(item = 'cinta'), siguiente()">
                Cinta de correr 🏃
              </v-btn>
              <br />
              <br />
              <v-btn @click="(item = 'bici'), siguiente()">
                Bici estatica 🚴‍♀️
              </v-btn>
              <br />
              <br />
              <v-btn @click="(item = 'pesas'), siguiente()">
                Pesas 🏋️
              </v-btn>
              <br />
              <br />
              <v-btn @click="(item = 'elasticas'), siguiente()">
                Bandas elasticas 🎽
              </v-btn>
            </center>
          </div>
          <div v-else-if="paso == 2">
            <center>
              <h1>¿Eres... ?</h1>
              <br />
              <v-btn @click="siguiente()">
                Novato 🥉
              </v-btn>
              <br />
              <br />
              <v-btn @click="siguiente()">
                Avanzado 🥈
              </v-btn>
              <br />
              <br />
              <v-btn @click="siguiente()">
                Profesional 🥇
              </v-btn>
            </center>
          </div>
          <div v-else-if="paso == 3">
            <center>
              <h1>¡ Buscando !</h1>
              <br />
              <br />
              <v-progress-circular
                :rotate="-90"
                :size="200"
                :width="20"
                :value="progreso_circular"
                color="white"
              >
                <h2>{{ progreso_circular }}</h2>
              </v-progress-circular>
              <br />
              <br />
              <br />
              <v-btn v-if="visibilidad_final" color="success" @click="final()">
                <h2>💪 Abrir 💪</h2>
              </v-btn>
            </center>
          </div>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>
<script>
import { StoreDB } from "@/services/fireinit.js";

export default {
  props: ["id"],
  data() {
    return {
      paso: 0,
      numero_total_de_pasos: 3,
      item: "cinta",
      genero: "hombre",
      relacion: "familia",
      dinero: "poco",
      af_playa: "",
      af_peli: "",
      interval: {},
      progreso_circular: 0,
      visibilidad_final: false,

      areas: {},

      actual: ""
    };
  },
  created() {
    //this.actual = this.$route.params.id;
    //console.log(this.$route);
    //console.log(this.$router)
    StoreDB.collection("areas")
      .get()
      .then(querySnapshot => {
        const documents = querySnapshot.docs.map(doc => doc.data());
        //console.log(documents);
        this.areas = documents;
        // do something with documents
      });
  },

  mounted() {
    //console.log(this.$route.params.id);
    //this.$ga.page('/')
  },
  methods: {
    siguiente() {
      this.paso = this.paso + 1;

      var incremento = Math.ceil(100 / this.numero_total_de_pasos);
      if (this.paso == 1) {
        this.$Progress.set(incremento);
      } else if (this.paso == this.numero_total_de_pasos) {
        //Barra de progreso al maximo
        this.$Progress.set(100);

        //Progreso circular
        this.interval = setInterval(() => {
          if (this.progreso_circular >= 100) {
            this.visibilidad_final = true;
          } else {
            //this.progreso_circular += 10
            var a_sumar = Math.floor(Math.random() * 3 + 1) + 12;
            if (a_sumar + this.progreso_circular > 100) {
              this.progreso_circular = 100;
            } else {
              this.progreso_circular += a_sumar;
            }
          }
        }, 300);
      } else {
        this.$Progress.increase(incremento);
      }
    },
    final() {
      var link = "";
      if (this.item == "cinta") {
        link = "https://amzn.to/3lHzN7z"; //Cinta 339
        //https://amzn.to/3ecXeD2
        //https://amzn.to/2xlbt86
        //https://amzn.to/34wI3Aa
      } else if (this.item == "bici") {
        link = "https://amzn.to/3nOLGuc"; //Bici 200
      } else if (this.item == "pesas") {
        link = "https://amzn.to/3aMYnih"; //Pesas 20
      } else {
        link = "https://amzn.to/34MlLKY"; //Elasticas 17
      }
      location.href = link;
    }
  },
  name: "App"
};
</script>

<style scoped>
h1 {
  font-size: 32px;
}
h2 {
  font-size: 24px;
}
button {
  font-size: 18px;
}
</style>
