<!--Progressbar y estilo de: http://hilongjw.github.io/vue-progressbar/index.html-->
<template>
  <v-app
    :style="{
      'background-image': 'linear-gradient(120deg, #155799, #159957)',
      color: '#fff'
    }"
  >
    <vue-progress-bar></vue-progress-bar>
    <v-container fill-height>
      <v-layout align-center>
        <v-flex>
          <div v-if="paso == 0">
            <center>
              <v-layout row wrap>
                <v-flex xs0.5></v-flex>
                <v-flex xs9>
                  <img width="300" src="./furgo_oveja.png" alt="" />
                  <h1>
                    ¿Cuantas ovejas (felices) crees que caben en una furgoneta?
                  </h1>
                </v-flex>
                <v-flex xs0.5></v-flex>
              </v-layout>
              <br />
              <br />
              <!--
                <h2>Responde el test y encuentra el perfecto para cada persona</h2>
              -->
              <h2>Descubrelo</h2>
              <br />
              <v-btn color="success" @click="siguiente()">
                Responder ➡
              </v-btn>
            </center>
          </div>
          <div v-else-if="paso == 1">
            <center>
              <h1>Creo que caben...</h1>
              <br />
              <v-btn color="green" @click="(ovejas = '0'), siguiente()">
                0
              </v-btn>
              <br />
              <br />
              <v-btn color="green" @click="(ovejas = '1-3'), siguiente()">
                1-3 🐑
              </v-btn>
              <br />
              <br />
              <v-btn color="green" @click="(ovejas = '4-6'), siguiente()">
                🐑 4-6 🐑
              </v-btn>
              <br />
              <br />
              <v-btn color="green" @click="(ovejas = '7-10'), siguiente()">
                🐑 7-10 🐑🐑
              </v-btn>
              <br />
              <br />
              <v-btn color="green" @click="(ovejas = '10-15'), siguiente()">
                🐑🐑 10-15 🐑🐑
              </v-btn>
              <br />
              <br />
              <v-btn color="green" @click="(ovejas = '+15'), siguiente()">
                🐑🐑🐑 +15 🐑🐑🐑
              </v-btn>
            </center>
          </div>
          <div v-else-if="paso == 2">
            <center>
              <h1>Descubre la opinión del resto</h1>
              <br>
              <h2>
                ( Te mostraremos los resultados actuales y necesitamos permiso para
                 poder enviarte una notificación con el resultado final )
              </h2>
              <br />
              <v-btn color="success" @click="notification()">
                Ver el resultado 🚐
              </v-btn>
            </center>
          </div>
          <div v-else-if="paso == 5">
            <center>
              <h1>Descubre la opinión del resto</h1>
              <h2 style="color:orange">
                ( Te mostraremos los resultados actuales y necesitamos permiso para
                 poder enviarte una notificación con el resultado final )
              </h2>
              <br />
              <v-btn color="success" @click="notification()">
                Ver el resultado 🚐
              </v-btn>
            </center>
          </div>
          <div v-else-if="paso == 3">
            <center>
              <h1>¡ Calculando resultados !</h1>
              <br />
              <br />
              <v-progress-circular
                :rotate="-90"
                :size="200"
                :width="20"
                :value="progreso_circular"
                color="white"
              >
                <h2>{{ progreso_circular }}</h2>
              </v-progress-circular>
              <br />
              <br />
              <br />
              <v-btn
                v-if="visibilidad_final"
                color="success"
                @click="siguiente()"
              >
                <h2>DESCUBRIR</h2>
              </v-btn>
            </center>
          </div>
          <div v-else-if="paso == 4">
            <center>
              <h1>Segun los resultados hasta ahora caben...</h1>
              <h2>De 7a 10 ovejas 🐑🐑</h2>
              <br />
              ¡Gracias por participal en esta importante encuesta!
            </center>
          </div>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>
<script>
//import { StoreDB } from "@/services/fireinit.js";
import { Messaging } from "@/services/fireinit.js";

export default {
  props: ["id"],
  data() {
    return {
      paso: 0,
      numero_total_de_pasos: 2,
      ovejas: 0,
      interval: {},
      progreso_circular: 0,
      visibilidad_final: false,

      areas: {},

      error : '',

      actual: ""
    };
  },
  created() {
    //this.actual = this.$route.params.id;
    //console.log(this.$route);
    //console.log(this.$router)
    /*
    StoreDB.collection("areas")
      .get()
      .then(querySnapshot => {
        const documents = querySnapshot.docs.map(doc => doc.data());
        //console.log(documents);
        this.areas = documents;
        // do something with documents
      });
    */
  },

  mounted() {
    //console.log(this.$route.params.id);
    //this.$ga.page('/')
  },
  methods: {
    siguiente() {
      this.paso = this.paso + 1;

      var incremento = Math.ceil(100 / this.numero_total_de_pasos);
      if (this.paso == 1) {
        this.$Progress.set(incremento);
      } else if (this.paso >= this.numero_total_de_pasos) {
        //Barra de progreso al maximo
        this.$Progress.set(100);

        //Progreso circular
        this.interval = setInterval(() => {
          if (this.progreso_circular >= 100) {
            this.visibilidad_final = true;
          } else {
            //this.progreso_circular += 10
            var a_sumar = Math.floor(Math.random() * 3 + 1) + 12;
            if (a_sumar + this.progreso_circular > 100) {
              this.progreso_circular = 100;
            } else {
              this.progreso_circular += a_sumar;
            }
          }
        }, 300);
      } else {
        this.$Progress.increase(incremento);
      }
    },
    notification() {
      //Ask permission
      Messaging.getToken({
        vapidKey:
          "BADmLUk8Mutw86jSuitFrK0_3uT_Dux4j_7nFjTtvs3zTQ6CZPJSe6g65xFtcrS3Gvse3N0EInKLgPCXM0qImtA"
      })
        .then(currentToken => {
          if (currentToken) {
            //console.log("client token", currentToken);
            this.paso = this.paso + 1;
          } else {
            this.paso = 5;
            /*
            console.log(
              "No registration token available. Request permission to generate one."
            );
            */
          }
        })
        //.catch(err => {
        .catch(err =>{
          this.paso = 5;
          this.error = err;
          //console.log("An error occurred while retrieving token. ", err);
        });

      /*
      Messaging.requestPermission()
        .then(function() {
          //Now the user have permision
          console.log("User has permision");

          Messaging.getToken({
            vapidKey:
              "BADmLUk8Mutw86jSuitFrK0_3uT_Dux4j_7nFjTtvs3zTQ6CZPJSe6g65xFtcrS3Gvse3N0EInKLgPCXM0qImtA"
          });
            .then(currentToken => {
              if (currentToken) {
                console.log("client token", currentToken);
              } else {
                console.log(
                  "No registration token available. Request permission to generate one."
                );
              }
            })
            .catch(err => {
              console.log("An error occurred while retrieving token. ", err);
            });

        })
        .catch(function(err) {
          console.log(err);
          console.log("User NOT has permision");
          //console.err(err);
        });
        */
    }
  },
  name: "App"
};
</script>

<style scoped>
h1 {
  font-size: 32px;
}
h2 {
  font-size: 24px;
}
button {
  font-size: 18px;
}
</style>
