<!--Progressbar y estilo de: http://hilongjw.github.io/vue-progressbar/index.html-->
<template>
  <v-app
    :style="{
      'background-image': 'linear-gradient(120deg, #155799, #159957)',
      color: '#fff'
    }"
  >
    <vue-progress-bar></vue-progress-bar>
    <v-container fill-height>
      <v-layout align-center>
        <v-flex>
          <div v-if="paso == 0">
            <center>
              <!--
              <h1>🎁¿Buscas el regalo ideal?🎁</h1>
              <br>
              <h1>🎁</h1>
              <br>
              -->
              <v-layout row wrap>
                <v-flex xs0.5></v-flex>
                <v-flex xs1><h1>&nbsp;🎁</h1></v-flex>
                <v-flex xs9>
                  <h1>¿Buscas regalar el altavoz ideal? {{ id }}</h1>
                </v-flex>
                <v-flex xs1>
                  <h1>🎁&nbsp;</h1>
                </v-flex>
                <v-flex xs0.5></v-flex>
              </v-layout>
              <br />
              <br />
              <!--
                <h2>Responde el test y encuentra el perfecto para cada persona</h2>
              -->
              <h2>Responde el test y encuentra el perfecto</h2>
              <br />
              <v-btn color="success" @click="siguiente()">
                Comenzar ➡
              </v-btn>
            </center>
          </div>
          <div v-else-if="paso == 1">
            <center>
              <h1>¿Cuantos años tiene?</h1>
              <br />
              <v-btn @click="(edad = 'bebe'), siguiente()">
                Bebe 🍼
              </v-btn>
              <br />
              <br />
              <v-btn @click="(edad = 'nino'), siguiente()">
                Niño ⚽
              </v-btn>
              <br />
              <br />
              <v-btn @click="(edad = 'adolescente'), siguiente()">
                Adolescente 💄
              </v-btn>
              <br />
              <br />
              <v-btn @click="(edad = 'joven'), siguiente()">
                Joven 🚗
              </v-btn>
              <br />
              <br />
              <v-btn @click="(edad = 'adulto'), siguiente()">
                Adulto 🔨
              </v-btn>
              <br />
              <br />
              <v-btn @click="(edad = 'mayor'), siguiente()">
                Mayor 📢
              </v-btn>
            </center>
          </div>
          <div v-else-if="paso == 2">
            <center>
              <h1>¿Que es para ti?</h1>
              <br />
              <v-btn @click="(relacion = 'familia'), siguiente()">
                Familia 👨‍👩‍👧‍👧
              </v-btn>
              <br />
              <br />
              <v-btn @click="(relacion = 'amigo'), siguiente()">
                Amigo 🎳
              </v-btn>
              <br />
              <br />
              <v-btn @click="(edad = 'pareja'), siguiente()">
                Pareja 👫
              </v-btn>
              <br />
              <br />
              <v-btn @click="(edad = 'companero'), siguiente()">
                Compañero 💻
              </v-btn>
            </center>
          </div>
          <div v-else-if="paso == 3">
            <center>
              <h1>¿Cuanto quieres gastar?</h1>
              <br />
              <v-btn @click="(dinero = 'poco'), siguiente()">
                0-20€ 💷
              </v-btn>
              <br />
              <br />
              <v-btn @click="(dinero = 'medio'), siguiente()">
                20-50€ 💰
              </v-btn>
              <br />
              <br />
              <v-btn @click="(dinero = 'mucho'), siguiente()">
                50-100€ 💳
              </v-btn>
              <br />
              <br />
              <v-btn @click="(dinero = 'atope'), siguiente()">
                +100€ 💳💳
              </v-btn>
            </center>
          </div>
          <div v-else-if="paso == 4">
            <center>
              <h1>¡ Buscando el regalo perfecto !</h1>
              <br />
              <br />
              <v-progress-circular
                :rotate="-90"
                :size="200"
                :width="20"
                :value="progreso_circular"
                color="white"
              >
                <h2>{{ progreso_circular }}</h2>
              </v-progress-circular>
              <br />
              <br />
              <br />
              <v-btn v-if="visibilidad_final" color="success" @click="final()">
                <h2>🎁 Abrir 🎁</h2>
              </v-btn>
            </center>
          </div>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>
<script>
import { StoreDB } from "@/services/fireinit.js";

export default {
  props: ["id"],
  data() {
    return {
      paso: 0,
      numero_total_de_pasos: 4,
      edad: "bebe",
      genero: "hombre",
      relacion: "familia",
      dinero: "poco",
      af_playa: "",
      af_peli: "",
      interval: {},
      progreso_circular: 0,
      visibilidad_final: false,

      areas: {},

      actual: ""
    };
  },
  created() {
    //this.actual = this.$route.params.id;
    //console.log(this.$route);
    //console.log(this.$router)
    StoreDB.collection("areas")
      .get()
      .then(querySnapshot => {
        const documents = querySnapshot.docs.map(doc => doc.data());
        //console.log(documents);
        this.areas = documents;
        // do something with documents
      });
  },

  mounted() {
    //console.log(this.$route.params.id);
    //this.$ga.page('/')
  },
  methods: {
    siguiente() {
      this.paso = this.paso + 1;

      var incremento = Math.ceil(100 / this.numero_total_de_pasos);
      if (this.paso == 1) {
        this.$Progress.set(incremento);
      } else if (this.paso == this.numero_total_de_pasos) {
        //Barra de progreso al maximo
        this.$Progress.set(100);

        //Progreso circular
        this.interval = setInterval(() => {
          if (this.progreso_circular >= 100) {
            this.visibilidad_final = true;
          } else {
            //this.progreso_circular += 10
            var a_sumar = Math.floor(Math.random() * 3 + 1) + 12;
            if (a_sumar + this.progreso_circular > 100) {
              this.progreso_circular = 100;
            } else {
              this.progreso_circular += a_sumar;
            }
          }
        }, 300);
      } else {
        this.$Progress.increase(incremento);
      }
    },
    final() {
      var link = "";

      //Variables intermedias
      var cercana = false;
      if (this.relacion == "familia" || this.relacion == "pareja") {
        cercana = true;
      }

      //Bebe --> Solo importa la relacion y cuanto gastar
      if (this.edad == "bebe") {
        if (this.dinero == "poco") {
          if (cercana) {
            link = "https://amzn.to/2Rs7OcK"; //Huella y foto
          } else {
            link = "https://amzn.to/2RtFu9C"; //Biberon cohete
          }
        } else if (this.dinero == "medio") {
          if (cercana) {
            link = "https://amzn.to/2CSCYV9";
          } else {
            link = "https://amzn.to/2SdXFVr";
          }
        } else if (this.dinero == "mucho") {
          link = "https://amzn.to/2BcXyQ4"; //Silla para comer
        } else {
          link = "https://amzn.to/2DIxs9g"; //Hamaca para bebes
        }
      }
      //Nino
      else if (this.edad == "nino") {
        if (this.dinero == "poco") {
          link = "https://amzn.to/2BaejM2"; //
        } else if (this.dinero == "medio") {
          link = "https://amzn.to/2BdxX9E"; //Uno attack
        } else if (this.dinero == "mucho") {
          link = "https://amzn.to/2WvjE9B"; //Lego city
        } else {
          link = "https://amzn.to/2CWDTEl"; //Scalextric
        }
      }
      //Adolescente
      else if (this.edad == "adolescente") {
        if (this.dinero == "poco") {
          link = "https://amzn.to/2BahhQA"; //Diana dardos
        } else if (this.dinero == "medio") {
          link = "https://amzn.to/2BeCTex"; //Trivial Pursuit
        } else if (this.dinero == "mucho") {
          link = "https://amzn.to/2Wx8j8P"; //Drone
        } else {
          link = "https://amzn.to/2WrgXpH";
        }
      }
      //Joven
      else if (this.edad == "joven") {
        if (this.dinero == "poco") {
          link = "https://amzn.to/2BeFfdn"; //Mapa mundi rascar
        } else if (this.dinero == "medio") {
          link = "https://amzn.to/2Se7in6"; //100 cosas antes de morir
        } else if (this.dinero == "mucho") {
          link = "https://amzn.to/2Ba6tlx"; //Polaroid
        } else {
          link = "https://amzn.to/2UsjsGs";
        }
      }
      //Adulto
      else if (this.edad == "adulto") {
        if (this.dinero == "poco") {
          link = "https://amzn.to/2Se8bfn"; //Taza
        } else if (this.dinero == "medio") {
          link = "https://amzn.to/2Bag4bK";
        } else if (this.dinero == "mucho") {
          link = "https://amzn.to/2UrAbtm"; //Amazfit bip
        } else {
          link = "https://amzn.to/2Rucn6e";
        }
      }
      //Mayor
      else {
        if (this.dinero == "poco") {
          link = "https://amzn.to/2Sj4XXK"; //Puzzle
        } else if (this.dinero == "medio") {
          link = "https://amzn.to/2Bc7olo"; //Manta electrica
        } else if (this.dinero == "mucho") {
          link = "https://amzn.to/2Wtv11Q"; //Haz tu cerveza
        } else {
          link = "https://amzn.to/2UrCdtu"; //Reloj caro
        }
      }
      //this.$router.push('http;//www.cuponesfever.com')
      location.href = link;
    }
  },
  name: "App"
};
</script>

<style scoped>
h1 {
  font-size: 32px;
}
h2 {
  font-size: 24px;
}
button {
  font-size: 18px;
}
</style>
